import React, { useState, useEffect } from "react";
import "../SignUp/SignUp.scss";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../../../data/auth/authAction";
import google from "@assets/Images/google.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faEye,
  faEyeSlash,
  faSpinner,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Navbar from "../../../Layouts/Navbar/Navbar";
import ProgressBar from "../../../../reusableComps/ProgressBar";
import { GoogleLoginButton } from "../Component/GoogleAuth";
import { resetLogin } from "../../../../data/auth/authSlice";

const SignUp = () => {
  const [emailInput, setEmailInput] = useState("");
  const [phoneInput, setPhoneInput] = useState("");
  const [FirstNameInput, setFirstNameInput] = useState("");
  const [LastNameInput, setLastNameInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [genderInput, setGenderInput] = useState("");
  const [passwordInput2, setPasswordInput2] = useState("");
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState({
    score: 0,
    feedback: "",
    color: "red",
  });
  const [validPhoneNumber, setValidPhoneNumber] = useState(true);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.userInfo);

  useEffect(() => {
    if (user && user.sites.length < 1) {
      navigate("/verify");
    }
  }, [user, navigate]);
  function handleShow() {
    setShow(!show);
  }
  function handleShow1() {
    setShow1(!show1);
  }

  const dispatch = useDispatch();
  const { loading, userInfo, error, success, isLoggedin } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (success) {
      navigate("/verify");
    }
  }, [success, navigate]);

  const canSave =
    Boolean(emailInput) &&
    Boolean(FirstNameInput) &&
    Boolean(LastNameInput) &&
    Boolean(agreeToTerms) &&
    passwordStrength.score >= 50;

  function checkPasswordStrength(password) {
    let score = 0; // Initialize score
    let feedback = "";

    // Define weights for different criteria
    const WEIGHTS = {
      length: 0.3, // Heavily weight length
      upperCase: 0.2, // Medium weight for uppercase
      lowerCase: 0.15, // Medium weight for lowercase
      numbers: 0.1, // Medium weight for numbers
      specialChars: 0.2, // Medium weight for special characters
      entropy: 0.2, // Additional entropy weight
    };

    // Criteria checks
    const length = password.length;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /[0-9]/.test(password);
    const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    // Assign scores based on criteria
    if (length >= 8) score += (WEIGHTS.length * Math.min(16, length)) / 16; // Scale length up to 16 chars
    if (hasUpperCase) score += WEIGHTS.upperCase;
    if (hasLowerCase) score += WEIGHTS.lowerCase;
    if (hasNumbers) score += WEIGHTS.numbers;
    if (hasSpecialChars) score += WEIGHTS.specialChars;

    // Calculate entropy
    const uniqueChars = new Set(password).size;
    const entropy = Math.log2(uniqueChars) * length;
    if (entropy > 50) score += WEIGHTS.entropy;

    // Normalize score to 1
    score = Math.min(1, score);

    // Provide feedback based on the score
    if (score > 0.8) {
      feedback = "Strong password. Great job!";
    } else if (score >= 0.5) {
      feedback = "Moderate password. Consider adding special characters.";
    } else {
      feedback =
        "Weak password. Consider using more length and character variety.";
    }

    setPasswordStrength({
      score: Math.round(score * 100), // Return score as percentage
      feedback,
      color:
        score > 0.8
          ? "text-green-600"
          : score >= 0.5
          ? "text-orange-400"
          : "text-red-400",
    });
  }

  const validatePhoneInput = (phone) => {
    const phoneRegex = /^0[789]\d{9}$/;
    setValidPhoneNumber(phoneRegex.test(phone));
  };

  const data = {
    username: phoneInput,
    email: emailInput.toLowerCase(),
    password1: passwordInput,
    gender: genderInput,
    password2: passwordInput2,
    first_name: FirstNameInput,
    last_name: LastNameInput,
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    if (passwordInput === passwordInput2) {
      localStorage.setItem("user_email", data.email);
      dispatch(registerUser(data));
    } else {
      toast.error("Passwords Do not match");
    }
  };

  useEffect(() => {
    dispatch(resetLogin());
    const getSlug = Cookies.get("jellosite-current-slug");
    // const user = Cookies.get("jellosite-current-slug");
    const redirectUrl = new URLSearchParams(location.search).get("redirect");

    if (isLoggedin) {
      console.log(user, "dfjh");
      if (user && user.sites.length < 1) {
        navigate("/onboarding");
      } else if (user) {
        navigate("/sites");
      }
    }
  }, [isLoggedin, user, location.search, navigate, dispatch]);

  return (
    <>
      <Navbar />
      <div className="signup">
        <div className="auth-left-signup">
          <h1>Create your website in a few clicks</h1>
          <ul>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} color="#7F56D9" />
              <span>Fully responsive</span>
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} color="#7F56D9" />
              <span>Modular design</span>
            </li>
          </ul>
        </div>
        <div className="auth-right-signup">
          <div className="signup-card">
            <h2>Sign Up to JelloSite</h2>
            <form onSubmit={handleSubmit}>
              <div className="input">
                <div className="row-input">
                  <input
                    required
                    className="pl-2"
                    value={FirstNameInput}
                    onChange={(e) => setFirstNameInput(e.target.value)}
                    type="text"
                    placeholder="First Name"
                  />
                  <input
                    required
                    className="pl-2"
                    value={LastNameInput}
                    onChange={(e) => setLastNameInput(e.target.value)}
                    type="text"
                    placeholder="Last name"
                  />
                </div>
                <div className="w-full">
                  <div className="row-input">
                    <input
                      required
                      className="pl-2"
                      value={phoneInput}
                      onChange={(e) => setPhoneInput(e.target.value)}
                      onBlur={(e) => validatePhoneInput(e.target.value)}
                      type="number"
                      placeholder="Phone Number"
                    />
                    <select
                      required
                      value={genderInput}
                      onChange={(e) => setGenderInput(e.target.value)}
                      type=""
                      className="p-gender"
                      placeholder="gender"
                    >
                      <option value="" disabled>
                        Gender
                      </option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                  {!validPhoneNumber && (
                    <span className="text-red-500">Invalid Phone number</span>
                  )}
                </div>
                <input
                  required
                  className="pl-2"
                  value={emailInput}
                  onChange={(e) => setEmailInput(e.target.value)}
                  type="email"
                  placeholder="Email Address"
                />
                <div className="password-input">
                  <input
                    required
                    className="pl-2"
                    value={passwordInput}
                    onChange={(e) => {
                      setPasswordInput(e.target.value);
                      checkPasswordStrength(e.target.value);
                    }}
                    type={show ? "text" : "password"}
                    placeholder="Create Password"
                  />
                  <FontAwesomeIcon
                    icon={show ? faEye : faEyeSlash}
                    onClick={handleShow}
                  />
                </div>
                {passwordInput && (
                  <ProgressBar
                    color={passwordStrength.color.replace("text-", "bg-")}
                    progress={passwordStrength.score}
                    height={3}
                  />
                )}
                {passwordInput && (
                  <span className={passwordStrength.color}>
                    {passwordStrength.feedback}
                  </span>
                )}
                <div className="password-input">
                  <input
                    required
                    className="pl-2"
                    value={passwordInput2}
                    onChange={(e) => {
                      setPasswordInput2(e.target.value);
                    }}
                    type={show1 ? "text" : "password"}
                    placeholder="Confirm Password"
                  />
                  <FontAwesomeIcon
                    icon={show1 ? faEye : faEyeSlash}
                    onClick={handleShow1}
                  />
                </div>
                {passwordInput2 && passwordInput !== passwordInput2 && (
                  <p className="password-strength-weak">
                    Passwords do not match!
                  </p>
                )}
              </div>
              <div className="rem">
                <input
                  required
                  value={agreeToTerms}
                  onChange={() => setAgreeToTerms(!agreeToTerms)}
                  type="checkbox"
                />
                <span>
                  I agree with the{" "}
                  <Link
                    to="/terms"
                    className="link"
                    style={{ color: "#7F56D9" }}
                  >
                    Terms & Conditions{" "}
                  </Link>
                  of Jellosite
                </span>
              </div>
              <div className=" signup-btn flex items-center justify-center gap-3 flex-col">
                <button
                  className="disabled:bg-red-800 p-24 !w-full"
                  type="submit"
                >
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    "Sign Up"
                  )}
                </button>
                <div className="w-full">
                  <GoogleLoginButton />
                </div>
              </div>
              {/* {error && (
                <span className="error-msg">
                  <FontAwesomeIcon icon={faWarning} color="red" />
                  {typeof error === "string" ? error : JSON.stringify(error)}
                </span>
              )} */}
            </form>
            {/* <button>
              <img src={google} alt={google} />
              <span>Sign In with Google</span>
            </button> */}
            <span>
              Already have an account?{" "}
              <Link to="/login" style={{ color: "#7F56D9" }}>
                Sign In
              </Link>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
