import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { loginWithGoogle } from "../../../../data/auth/authSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export const GoogleLoginButton = () => {
  const dispatch = useDispatch();
  const GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID;
  const handleSuccess = (response) => {
    console.log("Google login response:", response);
    const accessToken = response.credential;

    if (!accessToken) {
      console.error("Access token not found in Google response");
      toast.error("Google login failed. Please try again.");
      return;
    }
    dispatch(loginWithGoogle(accessToken));
  };

  const handleError = (error) => {
    console.error("Google login error:", error);
    toast.error("Google login failed. Please try again.");
  };

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID} className="w-full">
      <GoogleLogin
        onSuccess={handleSuccess}
        onError={handleError}
        width={"100%"}
        logo_alignment="center"
      />
    </GoogleOAuthProvider>
  );
};
